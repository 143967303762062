import axios from 'axios';

const api = axios.create({
    baseURL:  'http://api.testonline.id/', // Ganti dengan URL backend Anda http://127.0.0.1:8000
    headers: {
        'Content-Type': 'application/json',
      },
    timeout: 10000,
});

// Tambahkan token ke setiap request jika tersedia
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token'); // Ambil token dari localStorage
  if (token) {
    config.headers.Authorization = `Token ${token}`; // Sertakan token dalam header
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default api;




