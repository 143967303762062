<template>
  <b-container fluid class="home-container py-5">
    <b-row>
      <!-- Kolom kiri: Deskripsi dan tombol Login -->
      <b-col cols="12" md="6" class="d-flex flex-column justify-content-center">
        <div class="text-center text-md-left px-3 px-md-0">
          <h1 class="display-4 font-weight-bold text-primary">Selamat Datang di Tryout Online</h1>
          <p class="lead text-secondary">
            Persiapkan diri Anda dengan layanan tryout terbaik. Pilih kategori yang sesuai dengan kebutuhan Anda, dan mulai perjalanan menuju kesuksesan!
          </p>
          <b-button variant="primary" size="lg" class="mt-3" @click="$router.push('/login')">
            Mulai Sekarang
          </b-button>
        </div>
      </b-col>

      <!-- Kolom kanan: Kategori Tryout -->
      <b-col cols="12" md="6">
        <b-card class="shadow-sm">
          <b-card-header class="bg-primary text-white text-center">
            <h4>Pilihan Kategori Tryout</h4>
          </b-card-header>
          <b-card-body>
            <b-list-group>
              <b-list-group-item
                v-for="(category, index) in categories"
                :key="index"
                @click="goToCategory(category.id)"
                action
                class="category-item"
              >
                <b-row>
                  <b-col cols="8" sm="12" class="mb-2 mb-md-0">
                    <h5 class="mb-1 text-primary">{{ category.name }}</h5>
                    <p class="mb-1 text-secondary">{{ category.description }}</p>
                  </b-col>
                  <b-col cols="4" sm="12" class="text-right text-md-right">
                    <span class="text-success font-weight-bold">Rp {{ category.price }}</span>
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-list-group>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      categories: [], // Menyimpan kategori dari API
    };
  },
  methods: {
    async fetchCategories() {
      try {
        const response = await api.get("/categories/");
        this.categories = response.data; // Pastikan data diterima dengan format yang benar
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    },
    goToCategory(categoryId) {
      this.$router.push(`/categories/${categoryId}`);
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>

<style scoped>
.home-container {
  background: linear-gradient(to right, #f8f9fa, #e9ecef);
  min-height: 100vh;
}

.category-item {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .category-item {
    margin-bottom: 10px;
  }

  .home-container h1 {
    font-size: 1.8rem;
  }

  .home-container .lead {
    font-size: 1rem;
  }
}
</style>
