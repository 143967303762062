<template>
  <div id="app">
    <nav class="navbar">
      <div class="navbar-left">
        <!-- Link navigasi -->
        <router-link class="nav-link" to="/">Home</router-link>
        <router-link class="nav-link" v-if="!isLoggedIn" to="/login">Login</router-link>
        <router-link class="nav-link" v-if="!isLoggedIn" to="/register">Register</router-link>
      </div>
      <div class="navbar-right" v-if="isLoggedIn">
        <span class="user-email">{{ userEmail }}</span>
        <button class="logout-button" @click="logout">Logout</button>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    // Cek apakah pengguna sudah login berdasarkan token di localStorage
    isLoggedIn() {
      return !!localStorage.getItem("token");
    },
    // Ambil email pengguna dari localStorage
    userEmail() {
      return localStorage.getItem("userEmail") || "Guest";
    },
  },
  methods: {
    // Fungsi logout
    logout() {
      localStorage.removeItem("token"); // Hapus token dari localStorage
      localStorage.removeItem("userEmail"); // Hapus email dari localStorage
      alert("Anda telah logout.");
      this.$router.push("/login"); // Arahkan ke halaman login
    },
  },
};
</script>

<style scoped>
/* Navigasi */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #343a40;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-link {
  color: white;
  text-decoration: none;
  margin-right: 15px;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ffc107;
}

.user-email {
  font-weight: bold;
  font-size: 16px;
  color: #ffc107;
}

.logout-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.logout-button:hover {
  background-color: #c82333;
}
</style>
