<template>
  <div class="tryout-container">
    <!-- Kolom Kiri -->
    <div class="left-column">
      <h3>Informasi Peserta</h3>
      <p><strong>Nama:</strong> {{ user.name }}</p>
      <p><strong>Email:</strong> {{ user.email }}</p>
      <h3>Informasi Kategori</h3>
      <p><strong>Kategori:</strong> {{ category.name }}</p>
      <p><strong>Jumlah Soal:</strong> {{ questions.length }}</p>
    </div>

    <!-- Kolom Tengah -->
    <div class="middle-column">
      <div class="instructions">
        <h3>Petunjuk Pengerjaan</h3>
        <p>Jawablah setiap soal dengan memilih salah satu jawaban yang tersedia. Klik "Submit Tryout" setelah selesai.</p>
        <p><strong>Waktu Tersisa:</strong> {{ Math.floor(remainingTime / 60) }}:{{ (remainingTime % 60).toString().padStart(2, "0") }}</p>
      </div>
      <div class="question-container" v-if="currentQuestion">
        <h4>Soal {{ currentIndex + 1 }} dari {{ questions.length }}</h4>
        <p>{{ currentQuestion.question_text }}</p>
        <div v-for="(option, idx) in currentQuestion.options" :key="idx">
          <label>
            <input
              type="radio"
              :name="'question-' + currentQuestion.id"
              :value="option.key"
              v-model="userAnswers[currentQuestion.id]"
            />
            {{ option.key }}. {{ option.value }}
          </label>
        </div>
        <div class="navigation-buttons">
          <button @click="prevQuestion" :disabled="currentIndex === 0">Kembali</button>
          <button @click="nextQuestion" :disabled="currentIndex === questions.length - 1">Lanjut</button>
        </div>
      </div>
      <div v-else>
        <p>Loading...</p>
      </div>
      <button @click="submitTryout" class="submit-button">Submit Tryout</button>
    </div>

    <!-- Kolom Kanan -->
    <div class="right-column">
      <button class="stop-button" @click="stopTryout">Akhiri Tryout</button>
      <div class="question-icons">
        <div
          v-for="(question, index) in questions"
          :key="index"
          :class="['question-icon', { answered: userAnswers[question.id] }]"
          @click="goToQuestion(index)"
          title="Klik untuk menuju soal ini"
        >
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      user: {
        name: "John Doe", // Akan diambil dari backend
        email: localStorage.getItem("userEmail") || "",
      },
      category: {
        name: "Kategori Tryout", // Akan diambil dari backend
      },
      questions: [],
      userAnswers: {},
      currentIndex: 0,
      remainingTime: localStorage.getItem("remainingTime") || 3600, // Default: 1 jam
      tryoutCompleted: false,
      timer: null,
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
  },
  methods: {
    async fetchQuestions(categoryId) {
      try {
        const response = await api.get(`/categories/${categoryId}/questions/`);
        this.questions = response.data.map((question) => ({
          ...question,
          options: Object.entries(question.options).map(([key, value]) => ({
            key,
            value,
          })),
        }));
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    },
    nextQuestion() {
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
      }
    },
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    goToQuestion(index) {
      this.currentIndex = index;
    },
    stopTryout() {
      const confirmed = confirm("Apakah Anda yakin ingin mengakhiri tryout?");
      if (confirmed) {
        this.$router.push("/dashboard");
      }
    },
    async submitTryout() {
      const unanswered = this.questions.filter(
        (q) => !this.userAnswers[q.id]
      );
      if (unanswered.length > 0) {
        const confirmed = confirm(
          `Anda belum menjawab ${unanswered.length} soal. Lanjutkan submit?`
        );
        if (!confirmed) return;
      }

      const answers = Object.entries(this.userAnswers).map(([id, option]) => ({
        question_id: id,
        selected_option: option,
      }));
      try {
        const response = await api.post("/submit-tryout/", {
          category_id: this.$route.params.id,
          answers,
        });
        localStorage.setItem(
          "tryoutResult",
          JSON.stringify({
            score: response.data.score,
            results: response.data.results,
          })
        );
        this.tryoutCompleted = true;
        this.$router.push("/hasil-tryout");
      } catch (error) {
        console.error("Error submitting tryout:", error);
      }
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.remainingTime--;
        localStorage.setItem("remainingTime", this.remainingTime);
        if (this.remainingTime <= 0) {
          clearInterval(this.timer);
          this.submitTryout();
        }
      }, 1000);
    },
  },
  mounted() {
    const categoryId = this.$route.params.id;

    if (!categoryId) {
      console.error("Category ID not found in route.");
      return;
    }

    const savedAnswers = JSON.parse(localStorage.getItem(`answers-${categoryId}`));
    if (savedAnswers) this.userAnswers = savedAnswers;

    const savedTime = localStorage.getItem("remainingTime");
    if (savedTime) this.remainingTime = parseInt(savedTime, 10);

    this.fetchQuestions(categoryId);
    this.startTimer();
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  beforeRouteLeave(to, from, next) {
    if (!this.tryoutCompleted) {
      const confirmLeave = confirm(
        "Anda belum menyelesaikan tryout. Yakin ingin keluar?"
      );
      if (!confirmLeave) {
        next(false);
      } else {
        next();
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.tryout-container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 20px;
  padding: 20px;
}
.left-column,
.middle-column,
.right-column {
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
}
.middle-column {
  background-color: #f9f9f9;
}
.instructions {
  margin-bottom: 20px;
  background-color: #e8f5e9;
  padding: 10px;
  border-radius: 5px;
}
.navigation-buttons button {
  margin: 5px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.navigation-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
.stop-button {
  display: block;
  width: 100%;
  background-color: #f44336;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
  cursor: pointer;
}
.question-icons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.question-icon {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f5f5f5;
}
.question-icon.answered {
  background-color: #4caf50;
  color: white;
}
</style>
