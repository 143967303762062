<template>
  <b-container fluid class="dashboard-container py-5">
    <b-row>
      <!-- Header Dashboard -->
      <b-col cols="12" class="text-center mb-4">
        <h1 class="text-primary font-weight-bold">Dashboard</h1>
        <p class="text-secondary">Selamat datang, <strong>{{ email }}</strong></p>
      </b-col>
    </b-row>

    <!-- Kategori yang Dibeli -->
    <b-row>
      <b-col cols="12">
        <b-card class="shadow-lg">
          <b-card-header class="bg-primary text-white text-center">
            <h3>Kategori yang Dibeli</h3>
          </b-card-header>
          <b-card-body>
            <div v-if="categories.length > 0">
              <b-row>
                <b-col
                  v-for="category in categories"
                  :key="category.id"
                  cols="12"
                  md="6"
                  lg="4"
                  class="mb-4"
                >
                  <b-card class="h-100 shadow-sm">
                    <b-card-title class="text-primary text-center">
                      {{ category.name }}
                    </b-card-title>
                    <b-card-text class="text-secondary">
                      <p>Status: 
                        <span
                          :class="{
                            'text-success': category.payment_status === 'paid',
                            'text-danger': category.payment_status !== 'paid',
                          }"
                        >
                          {{ category.payment_status === 'paid' ? 'Lunas' : 'Belum Lunas' }}
                        </span>
                      </p>
                    </b-card-text>
                    <b-button
                      v-if="category.payment_status === 'paid'"
                      variant="success"
                      block
                      @click="startTryout(category.id)"
                    >
                      Mulai Tryout
                    </b-button>
                    <b-alert v-else variant="warning" show>
                      Selesaikan pembayaran untuk memulai.
                    </b-alert>
                  </b-card>
                </b-col>
              </b-row>
            </div>
            <div v-else class="text-center">
              <p class="text-muted">Anda belum membeli kategori tryout.</p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      email: localStorage.getItem("userEmail") || "",
      categories: [],
    };
  },
  methods: {
    // Ambil kategori yang dibeli dari backend
    async fetchPurchasedCategories() {
      try {
        const response = await api.get("/user/purchased-categories/", {
          params: { email: this.email },
        });
        this.categories = response.data.categories;
      } catch (error) {
        console.error("Error fetching purchased categories:", error);
      }
    },

    // Arahkan ke halaman tryout
    startTryout(categoryId) {
      this.$router.push(`/tryout/${categoryId}`);
    },
  },
  mounted() {
    if (!this.email) {
      alert("Silakan login terlebih dahulu.");
      this.$router.push("/login");
    } else {
      this.fetchPurchasedCategories();
    }
  },
};
</script>

<style scoped>
.dashboard-container {
  background: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
  min-height: 100vh;
}

.shadow-lg {
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
}

.shadow-sm {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.text-primary {
  color: #007bff !important;
}

.text-secondary {
  color: #6c757d !important;
}
</style>
