<template>
  <b-container fluid class="category-detail-container py-5">
    <b-row>
      <!-- Kolom Kiri: Informasi Kategori -->
      <b-col cols="12" md="6" class="d-flex flex-column justify-content-center">
        <b-card class="shadow-lg">
          <b-card-header class="bg-primary text-white text-center">
            <h3>{{ category.name }}</h3>
          </b-card-header>
          <b-card-body>
            <p class="text-secondary">{{ category.description }}</p>
            <h5 class="text-success font-weight-bold">
              Harga: Rp {{ category.price }}
            </h5>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Kolom Kanan: Pembayaran dan Status -->
      <b-col cols="12" md="6">
        <b-card class="shadow-lg">
          <b-card-header class="bg-secondary text-white text-center">
            <h4>Pembelian Paket</h4>
          </b-card-header>
          <b-card-body>
            <!-- Form Pembelian -->
            <b-form @submit.prevent="buyPackage">
              <b-form-group label="Masukkan Email" label-for="email">
                <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  required
                  placeholder="Masukkan email Anda"
                ></b-form-input>
              </b-form-group>
              <b-button variant="success" block type="submit">Beli Paket</b-button>
            </b-form>
            <hr />
            <!-- Status Pembayaran -->
            <div v-if="status === 'paid'" class="text-center mt-4">
              <p class="text-success font-weight-bold">
                Pembayaran berhasil! Anda dapat memulai tryout.
              </p>
              <b-button variant="primary" block @click="startTryout">
                Mulai Tryout
              </b-button>
            </div>
            <div v-else class="text-center mt-4">
              <p class="text-warning">
                Anda belum membayar paket ini. Silakan selesaikan pembayaran.
              </p>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import api from "@/services/api";

export default {
  data() {
    return {
      email: localStorage.getItem("userEmail") || "",
      status: "unpaid", // Status awal
      category: {}, // Data kategori
    };
  },
  methods: {
    // Ambil detail kategori
    async fetchCategoryDetails() {
      const categoryId = this.$route.params.id;
      try {
        const response = await api.get(`/categories/${categoryId}/`);
        this.category = response.data;
      } catch (error) {
        console.error("Error fetching category details:", error);
      }
    },

    // Fungsi untuk membeli paket
    async buyPackage() {
      const categoryId = this.$route.params.id;

      if (!this.email) {
        alert("Silakan masukkan email untuk melanjutkan.");
        return;
      }

      try {
        // Periksa apakah email sudah terdaftar sebagai guest
        const emailExists = await this.checkGuestExists();

        if (!emailExists) {
          // Registrasi guest jika email belum ada
          await this.guestRegister();
        }

        // Lanjutkan dengan proses pembelian
        const response = await api.post(`/categories/${categoryId}/buy/`, {
          email: this.email,
        });

        const snapToken = response.data.snap_token;

        window.snap.pay(snapToken, {
          onSuccess: () => {
            alert("Pembayaran berhasil!");
            this.checkPaymentStatus(); // Perbarui status pembayaran
          },
          onPending: () => {
            alert("Pembayaran sedang diproses.");
          },
          onError: () => {
            alert("Pembayaran gagal. Silakan coba lagi.");
          },
          onClose: () => {
            alert("Anda menutup popup tanpa menyelesaikan pembayaran.");
          },
        });
      } catch (error) {
        console.error("Error during category purchase:", error);
        alert("Terjadi kesalahan saat membeli paket. Silakan coba lagi.");
      }
    },

    async checkGuestExists() {
      try {
        const response = await api.get("/auth/check-email/", {
          params: { email: this.email },
        });
        return response.data.exists; // Respons backend: { exists: true/false }
      } catch (error) {
        console.error("Error checking email existence:", error);
        return false; // Anggap email belum ada jika terjadi error
      }
    },


    // Fungsi untuk mengecek status pembayaran
    async checkPaymentStatus() {
      const categoryId = this.$route.params.id;
      try {
        const response = await api.get(
          `/categories/${categoryId}/check-payment/`,
          {
            params: { email: this.email },
          }
        );
        this.status = response.data.status; // Perbarui status
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    },

    // Fungsi untuk memulai tryout
    async startTryout() {
      const categoryId = this.$route.params.id;

      try {
        const response = await api.get(`/categories/${categoryId}/start-tryout/`, {
          params: { email: this.email },
        });

        if (response.status === 200) {
          this.$router.push(`/tryout/${categoryId}`);
        }
      } catch (error) {
        alert("Silahkan Login untuk meulai tryout ini (cek username dan password pada email).");
      }
    },

    async guestRegister() {
      if (!this.email) {
        alert("Silakan masukkan email untuk mendaftar.");
        return;
      }

      try {
        const response = await api.post("/auth/guest-register/", {
          email: this.email,
          name: "Guest", // Nama opsional
        });
        console.log("Guest registered successfully:", response.data);
        alert(response.data.message);
      } catch (error) {
        console.error("Error during guest registration:", error.response.data);
        if (error.response && error.response.data.message === "User already exists") {
          alert("Email sudah terdaftar sebagai guest.");
        } else {
          alert("email sudah terdaftar, silahkan login untuk cek paket trayout");
        }
      }
    },

  },
  async mounted() {
    this.fetchCategoryDetails(); // Ambil data kategori saat komponen dimuat
    this.checkPaymentStatus(); // Periksa status pembayaran
  },
};
</script>

<style scoped>
.category-detail-container {
  background: linear-gradient(to bottom right, #f8f9fa, #e9ecef);
  min-height: 100vh;
}

.shadow-lg {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
